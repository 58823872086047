<template>
  <div id="approvalDialog">
    <el-dialog
        title="退款审批"
        :visible.sync="show"
        :close-on-press-escape="false"
        @closed="reset"
    >
      <el-form
          :model="form"
          :rules="rules"
          ref="Form"
          label-width="100px"
      >
        <el-form-item label="审批备注">
          <el-input
              v-model="form.handleRemark"
              placeholder="请输入审批备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="审批状态" prop="refundStatus">
          <el-radio-group v-model="form.refundStatus">
            <el-radio label="已同意">同意退款</el-radio>
            <el-radio label="已拒绝">驳回申请</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
            type="primary"
            size="medium"
            @click="confirmBtn"
            :loading="loading"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show:false,
      loading:false,
      refundId:"",
      form:{
        handleRemark:"",
        refundStatus:"",
      },
      rules:{
        refundStatus:[
          { required: true, message: '请选择审批状态', trigger: 'change' }
        ]
      }
    }
  },
  methods:{
    reset(){
      this.form = {
        handleRemark:"",
        refundStatus:"",
      }
      this.orderId=""
      this.$refs["Form"].resetFields();
    },
    changeShow(){
      this.show=!this.show;
    },
    confirmBtn(){
      this.$refs["Form"].validate(async (valid) => {
        if (valid) {
          try{
            this.loading = true;
            let res = await this.$http.post(`/order/refund/handle`,{
              requestId:this.refundId,
              handleRemark:this.form.handleRemark,
              refundStatus:this.form.refundStatus,
            });
            if(res.code===0){
              this.$message.success("操作成功");
              this.show=false;
              this.reset();
              this.$emit("refresh");
            }else{
              this.$message.error(res.msg);
            }
            this.loading = false;
          }catch (err){
            this.$message.error(err.message);
            this.loading = false;
          }
        }
      });
    }
  }
}
</script>

<style lang="scss">
#approvalDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
